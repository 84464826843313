import style from './PageDetailInformation.module.scss'

interface PageDetailInformationProps {
  title: string
  intro: string
  questionList: Array<string>
}

const PageDetailInformation = ({
  title,
  intro,
  questionList,
}: PageDetailInformationProps): JSX.Element => {
  return (
    <div className="row">
      <div className={`col ${style.list}`}>
        <div className={`mt-5 ${style.title}`}>{title}</div>
        <div className={`mt-3 ${style.info}`}>{intro}</div>
        <div className={`mt-3 ${style.orderList}`}>
          <ul>
            {questionList.map((question) => (
              <li key={question}>{question}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PageDetailInformation
