import styles from './Card.module.scss'
import {ReactComponent as ScalesIcon} from './weight.svg'
import {ReactComponent as ShoeIcon} from './distance.svg'
import {ReactComponent as HeartIcon} from './heart.svg'
import {ReactComponent as PressureIcon} from './blood-pressure.svg'

import {ReactComponent as WeightGraph} from './weight-graph.svg'
import {ReactComponent as DistanceGraph} from './distance-graph.svg'
import {ReactComponent as HeartbeatGraph} from './heartbeat-graph.svg'
import {ReactComponent as BloodPressureGraph} from './blood-pressure-graph.svg'

export enum CardIcons {
  WEIGHT = 'WEIGHT',
  DISTANCE = 'DISTANCE',
  HEARTBEAT = 'HEARTBEAT',
  BLOODPRESSURE = 'BLOODPRESSURE',
}

const icons = {
  [CardIcons.WEIGHT]: <ScalesIcon />,
  [CardIcons.DISTANCE]: <ShoeIcon />,
  [CardIcons.HEARTBEAT]: <HeartIcon />,
  [CardIcons.BLOODPRESSURE]: <PressureIcon />,
}

const graphs = {
  [CardIcons.WEIGHT]: <WeightGraph />,
  [CardIcons.DISTANCE]: <DistanceGraph />,
  [CardIcons.HEARTBEAT]: <HeartbeatGraph />,
  [CardIcons.BLOODPRESSURE]: <BloodPressureGraph />,
}

type IconSelector = keyof typeof CardIcons

export interface CardProps {
  iconName: IconSelector,
  value: string,
  label: string,
  lastUpdate: string
}

export function Card({iconName, value, label, lastUpdate}: CardProps) {
  return (
    <div className={styles[`${iconName.toLowerCase()}-card`]}>
      <div className={styles.icon}>{icons[iconName]}</div>
      <div className={styles.value}>{value}</div>
      <div className={styles.label}>{label}</div>
      <div className={styles.lastUpdate}>{lastUpdate}</div>
      <div className={styles.graph}>{graphs[iconName]}</div>
    </div>
  )
}
