import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './LoginSteps.module.scss'

interface LoginStepsProps {
  text: string
}

const LoginSteps = ({ text }: LoginStepsProps): JSX.Element => {
  const { t } = useTranslation()
  const steps: Array<string> = t(text, { returnObjects: true })
  return (
    <div className={`mt-4 mb-2 ${style.loginStep}`}>
      <ol start={0}>{steps.map((step) => <li key={step}>{step}</li>)}</ol>
    </div>
  )
}

export default LoginSteps
