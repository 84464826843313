import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../../config/store'
import { Vaccination } from '../../reducers/vaccination/vaccination-reducer'

const vaccinationSelector = createSelector(
  [(state: RootState) => state.vaccination.all],
  (vaccinations) => Object.values(vaccinations)
)

export const useVaccinations = (): Array<Vaccination> => {
  return useSelector(vaccinationSelector)
}