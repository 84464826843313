import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import BackToDashboardButton from '../../Button/BackToDashboardButton'
import Layout from '../Layout'
import styles from './MedicationScreen.module.scss'
import Table from '../../Table/Table'
import { useMedication } from '../../../hooks/Medication/medication'
import MedicationForm from '../../MedicationForm/MedicationForm'

export default function MedicationScreen(): JSX.Element {
  const {t} = useTranslation()
  const medication = [
    ['Medicijn', 'Startdatum', 'Einddatum', 'Dosering', 'Bron'],
    ...useMedication()
  ]

  const header = (
    <div className="container">
      <div className="row">
        <div className={styles.intro}>
          <h1>{t('dossier.detail.medication.title')}</h1>
          <p>{t('dossier.detail.medication.intro')}</p>
        </div>
      </div>
    </div>
  )

  const bottom = (
    <div className={clsx('container', styles.medicationMain)}>
      <div className="row">
        <div className="col-12">
          <BackToDashboardButton />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <MedicationForm />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.medication.current')}</h2>
          <Table definition={medication} />
        </div>
      </div>
    </div>
  )

  return (
    <Layout top={header} bottom={bottom} />
  )
}
