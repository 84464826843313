import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTE } from '../../config/route'
import LoginSteps from '../Form/LoginSteps'
import style from './Input.module.scss'

const OTPOptions: Array<{ value: string }> = [
  { value: '3' },
  { value: '4' },
  { value: '8' },
  { value: '5' },
  { value: '3' },
  { value: '9' },
]

const OTP = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className={`${style.otp}`}>
      <div className={`mb-3 ${style.input} ${style.otpText}`}>
        {t('login2fa.otp_title')}
      </div>
      <div className={`${style.otpInput}`}>
        {OTPOptions.map(({ value }, number) => (
          <input
            key={number}
            className={`form-control ${style.input}`}
            type="text"
            defaultValue={value}
            maxLength={1}
          />
        ))}
      </div>
      <LoginSteps text="login2fa.step_2FA_login" />
      <div className={style.otpAction}>
        <Link className="btn btn-primary" to={ROUTE.DASHBOARD}>{t('login.text')}</Link>
      </div>
    </div>
  )
}

export default OTP
