import { configureStore } from '@reduxjs/toolkit'
import dossierReducer from '../reducers/dossier/dossier-reducer'
import medicationReducer from '../reducers/medication/medication-reducer'
import vaccinationReducer from "../reducers/vaccination/vaccination-reducer";

export const store = configureStore({
  reducer: {
    dossiers: dossierReducer,
    medication: medicationReducer,
    vaccination: vaccinationReducer,
  },
  devTools: (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production')
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
