import { useTranslation } from 'react-i18next'
import styles from './Welcome.module.scss'

export function WelcomeTopMiddlePage(): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className={styles.middle}>
      <div><h2>{t('welcome.middle.title')}</h2></div>
      <div className={styles.text}>{t('welcome.middle.text_first')}</div>
      <div className={styles.middleTitleIntro}>{t('welcome.middle.title_intro_first')}</div>
      <div className={styles.text}>{t('welcome.middle.text_second')}</div>
      <div className={`mt-2 ${styles.text}`}><a href='https://medmij.nl' target="_blank">{t('welcome.middle.view_more')}</a></div>
      <div className={styles.middleTitleIntro}>{t('welcome.middle.title_intro_last')}</div>
      <div className={styles.text}>{t('welcome.middle.text_last')}</div>
      <div className={`mt-2 ${styles.text}`}><a href='https://www.patientenfederatie.nl/pgo-on-air' target="_blank">{t('welcome.middle.view_more')}</a></div>
    </div>
  )
}
