import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTE } from '../../../config/route'

export function ActionButton(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <button
      className="btn btn-primary"
      onClick={() => {
        navigate(ROUTE.LOGIN2FA)
      }}
    >
      {t('button.start_demo')}!
    </button>
  )
}
