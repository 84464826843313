import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import LoginSteps from './LoginSteps'

interface LoginFormProps {
  onClick: () => void
}
const LoginForm = ({ onClick }: LoginFormProps): JSX.Element => {
  const {t} = useTranslation()
  
  return (
    <Fragment>
      <div className="mb-2">
        <button className="btn btn-primary" onClick={onClick}>{t('login.button')}</button>
      </div>
      <LoginSteps text="login2fa.step_2FA_login" />
    </Fragment>
  )
}

export default LoginForm
