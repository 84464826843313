import React from 'react'
import {ReactComponent as Close} from '../../style/img/close.svg'
import styles from './Modal.module.scss'

export enum ModalType {
    MODAL_TYPE_APPOINTMENT,
    MODAL_TYPE_DOCUMENT,
    MODAL_TYPE_VACCINATION
}

interface ModalProps {
    closeModal: () => void;
    type: ModalType
}

const Modal: React.FC<ModalProps> = ({closeModal, type}) => {
    return (
        <div className={styles.modal}>
            <div className={styles.modalContainer}>
                <div className={styles.modalInner}>
                    <button className={styles.modalCloseButton} onClick={closeModal}>
                        <Close/>
                    </button>
                    <h2>Voorbeeld document</h2>
                    <p>
                        {
                            type === ModalType.MODAL_TYPE_VACCINATION ? 'In een echte PGO zie je hier meer details over je vaccinaties.' :
                                type === ModalType.MODAL_TYPE_DOCUMENT ? 'In een echte PGO zie je nu het document dat je wilt bekijken.' : ''
                        }
                    </p>
                </div>
            </div>
            <div className={styles.modalDocumentBg} onClick={closeModal}/>
        </div>
    )
}

export default Modal