import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Avatar } from '../../style/img/avatar.svg'
import style from './UserInformation.module.scss'

const UserInformation = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className={style.userInformation}>
      <Avatar className={style.avatar} />

      <div className={style.listItemText}>
        <div className={style.listItemTitle}>
          {t('userinfo.name')}
        </div>

        <ul className={style.listItemInfo}>
          <li>{t('userinfo.text')}</li>
        </ul>
      </div>
    </div>
  )
}

export default UserInformation
