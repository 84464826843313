import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Medication = [
  name: string,
  startDate: string,
  endDate: string,
  dosage: string,
  source: string
]

interface MedicationState {
  all: Array<Medication>
}

const initialState: MedicationState = {
  all: [
    [
      'OMEPRAZOL CAPSTLE MSR 20MG',
      '09-03-2022',
      '04-03-2023',
      '1 x per dag 1 capsule een half uur voor het ontbijt heel doorslikken, niet kauwen',
      'Huisartsenpraktijk Azuurblauw'
    ],
    [
      'APIXABAN TABLET 5MG (eliquis 5mg tablet filmomh)',
      '09-03-2022',
      '04-03-2023',
      '2 x per dag 1 tablet',
      'Huisartsenpraktijk Azuurblauw'
    ],
    [
      'LISINOPRIL TABLET 5MG',
      '09-03-2022',
      '04-03-2023',
      '1 x per dag 1 tablet',
      'Huisartsenpraktijk Azuurblauw'
    ],
  ]
}

const medicationSlice = createSlice({
  name: 'dossiers',
  initialState,

  reducers: {
    addMedication: (state: MedicationState, action: PayloadAction<Medication>): void => {
      if (state.all.find((medication: Medication) => medication[0] === action.payload[0]) === undefined) {
        state.all.unshift(action.payload)
      }
    }
  }
})

export const {addMedication} = medicationSlice.actions
export default medicationSlice.reducer
