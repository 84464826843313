import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../../config/store'
import { Medication } from '../../reducers/medication/medication-reducer'

const medicationSelector = createSelector(
  [(state: RootState) => state.medication.all],
  medication => medication
)

export const useMedication = (): Array<Medication> => {
  return useSelector(medicationSelector)
}