import BackToDashboardButton from '../../Button/BackToDashboardButton'
import HeaderText from '../../Header/HeaderText'
import Layout from '../Layout'
import AppointmentList from './AppointmentList'

const AppointmentPage = (): JSX.Element => {
  return (
    <div className="container">
      <BackToDashboardButton />
      <AppointmentList />
    </div>
  )
}

export default function Appointment(): JSX.Element {
  return (
    <Layout
      top={
        <HeaderText
          title="appointment.title"
          introduction="appointment.introduction"
        />
      }
      bottom={<AppointmentPage />}
    />
  )
}
