import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { store } from './config/store'

import './i18n'
import 'bootstrap/dist/css/bootstrap.css'
import './style/app.scss'
import App from './App'
import ScrollToTop from './components/ScrollRestoration/ScrollRestoration'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <ScrollToTop />
        <App />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
