import { useTranslation } from 'react-i18next'
import { Card, CardProps } from './Card'
import styles from './CardList.module.scss'

export default function CardList(): JSX.Element {
  const { t } = useTranslation()

  const cards: Array<CardProps> = t('cards.data', {returnObjects: true})

  return (
    <div className="row mt-5">
      <div className={`text-center ${styles.title}`}>{t('card.title')}</div>
      <div className="col">
        <div className={styles.tileList}>
          {cards.map(
            (card) => <Card key={card.label} {...card} />
          )}
        </div>
      </div>
    </div>
  )
}
