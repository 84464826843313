import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import style from './HeaderText.module.scss'

interface HeaderTextProps {
  title: string,
  introduction: string
}

const HeaderText = ({title, introduction}: HeaderTextProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="d-flex justify-content-center text-center">
        <div className={style.formWidth}>
          <div className="mb-3">
            <h1>{t(title)}</h1>
          </div>
          <div className="mb-4">
            <p>{t(introduction)}</p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

HeaderText.defaultProps = {
  title: 'dossier.title',
  introduction: 'dossier.introduction_text'
}

export default HeaderText
