import { DashboardLayout } from '../Layout'
import { TileList } from '../../Tile/TileList'
import UserInformation from '../../Header/UserInformation'
import CardList from '../../Card/CardList'

export function Dashboard(): JSX.Element {
  return (
    <DashboardLayout
      top={<UserInformation />}
      bottom={
        <main className="container">
          <div className="row">
            <div className="col">
              <TileList />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <CardList />
            </div>
          </div>
        </main>
      }
    />
  )
}
