import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ROUTE } from '../../config/route'

export interface Dossier {
  id: string,
  logo: 'hospital' | 'comment' | 'document' | 'doctor',
  title: string,
  retrievedAt: string,
  link?: string,
  new: boolean
}

interface DossierState {
  all: {
    [key: string]: Dossier
  }
}

const dossiers: {[key: string]: Dossier} = {
  '1a': {
    id: '1a',
    logo: 'hospital',
    title: 'Ziekenhuis Utrecht',
    retrievedAt: 'Opgehaald op 22 februari 2022 - 13:35:32',
    link: ROUTE.DOSSIER_HOSPITAL,
    new: false
  },
  '2b': {
    id: '2b',
    logo: 'comment',
    title: 'GGZ Huis Utrecht',
    retrievedAt: 'Opgehaald op 22 februari 2022 - 13:40:24',
    link: ROUTE.DOSSIER_GGZ,
    new: false
  }
}

const dossierSlice = createSlice({
  name: 'dossiers',
  initialState: {
    all: dossiers
  },
  reducers: {
    addDossier: (state: DossierState, action: PayloadAction<Dossier>): DossierState => {
      // If we do state.all[action.payload.id] = action.payload, it'll appear at the end, but it should be at the front.
      return {
        ...state,
        all: {
          [action.payload.id]: action.payload,
          ...state.all
        }
      }
    }
  }
})

export const {addDossier} = dossierSlice.actions
export default dossierSlice.reducer
