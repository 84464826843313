import React from 'react'
import { useTranslation } from 'react-i18next'
import BackToDashboardButton from '../../../Button/BackToDashboardButton'
import Table from '../../../Table/Table'
import Layout from '../../Layout'
import styles from './Physician.module.scss'

const medicine = [
  ['Medicijn', 'Startdatum', 'Einddatum', 'Dosering', 'Klachten en diagnose'],
  ['OMEPRAZOL CAPSTLE MSR 20MG', '09-03-2022', '04-03-2023', '1 x per dag 1 capsule een half uur voor het ontbijt heel doorslikken, niet kauwen', 'Boezemfibrilleren/-fladderen'],
  ['APIXABAN TABLET 5MG (eliquis 5mg tablet filmomh)', '09-03-2022', '04-03-2023', '2 x per dag 1 tablet', 'Boezemfibrilleren/-fladderen'],
  ['LISINOPRIL TABLET  5MG', '09-03-2022', '04-03-2023', '1 x per dag 1 tablet', 'Essentiele hypertensie zon']
]

const journal = [
  ['Datum', 'Klachten en diagnose', 'Evaluatie/plan', 'Omschrijving'],
  ['09-03-2022', 'Seborroisch eczeem/roos', 'P', 'KETOCONAZ AURO 20MG/G SHAMP / 60,00 ML / 2W-GE;OP DE REEDS GEWASSEN EN NOG NATTE HOOFDHUID I NMASSEREN, 3-5 MIN. LATE'],
  ['09-03-2022', 'Boezemfibrilleren/-fladderen Boezemfibrilleren/-fladderen', 'P', 'OMEPRAZOL CAPSULE MSR 20MG / 360,00 ST / 1D1C HVO'],
  ['09-03-2022', 'Essentiele hypertensie zonder orgaanbeschadiging Astma', 'P', 'APIXABAN TABLET 5MG / 720,00 ST / 2D1T'],
]

const episodes = [
  ['Klachten en diagnose', 'Laatste contact', 'Eerste contact', 'Startdatum', 'Stopdatum '],
  ['Essentiele hypertensie zonder orgaanbeschadiging', '09-03-2022', '24-06-2020', '24-06-2020', ''],
  ['Astma', '09-03-2022', '18-05-2021',  '01-01-1975', ''],
  ['Boezemfibrilleren/-fladderen', '09-03-2022', '19-03-2020', '12-02-2021', ''],
]

export default function Physician(): JSX.Element {
  const {t} = useTranslation()

  const intro = (<>
    <h1>{t('dossier.detail.physician.title')}</h1>
    <p>{t('dossier.detail.physician.intro')}</p>
  </>)

  const content = (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <BackToDashboardButton />
        </div>
      </div>
      
      <div className="row">
        <div className="col-12">
          <h2 id="medicine" className={styles.tableHeading}>{t('dossier.detail.physician.medicine')}</h2>
          <Table definition={medicine} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2 id="journal" className={styles.tableHeading}>{t('dossier.detail.physician.journal')}</h2>
          <Table definition={journal} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2 id="episodes" className={styles.tableHeading}>{t('dossier.detail.physician.episodes')}</h2>
          <Table definition={episodes} />
        </div>
      </div>
    </div>
  )

  return (
    <Layout top={intro} bottom={content}></Layout>
  )
}
