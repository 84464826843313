import { WelcomeLayout } from '../Layout'
import { BottomFooter } from './BottomFooter'
import { WelcomeBottomPage } from './WelcomeBottomPage'
import { WelcomeTopFooter } from './WelcomeTopFooter'
import { WelcomeTopMiddlePage } from './WelcomeTopMiddlePage'
import { WelcomeTopPage } from './WelcomeTopPage'

export default function Welcome(): JSX.Element {
  return (
    <WelcomeLayout
      top={<WelcomeTopPage />}
      middle={<WelcomeTopMiddlePage />}
      bottom={<WelcomeBottomPage />}
      footerTop={<WelcomeTopFooter />}
      footerBottom={<BottomFooter />}
    />
  )
}