import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import Table from '../../../Table/Table'
import Layout from '../../Layout'
import styles from './hospital.module.scss'
import BackToDashboardButton from '../../../Button/BackToDashboardButton'

const tables = {
  'Moeite met activiteiten': [
    ['Naam', 'Waarde', 'Datum'],
    ['Zich wassen', 'Moeite met wassen', '2001'],
  ],

  'Klachten en diagnoses': [
    ['Anatomische locatie', 'Lateraliteit','Probleemtype', 'Naam', 'Begindatum', 'Probleem status', 'Verificatie status', 'Toelichting'],
    ['Pols', 'Rechts', 'Diagnose', 'Polsfractuur', '2001', 'Niet actueel', 'Onbekend', 'Gevallen op kunstijsbaan'],
  ],

  'Waarschuwingen': [
    ['Alert naam', 'Begin Datum Tijd', 'Alert type'],
    ['Drager MRSA', '01/06/2010', 'Waarschuwing'],
  ],

  'Allergieën': [
    ['Veroorzakende stof', 'Categorie', 'Status', 'Begin Datum Tijd', 'Mate van kritiek zijn', 'Laatste reactie Datum Tijd', 'Symptoom', 'Ernst'],
    ['Bijengif', 'Stof of product', 'Actief', '08/11/2008', 'Ernstig', '15/11/2009', 'Misselijk en braken', 'Ernstig'],
  ],

  'Medische hulpmiddelen': [
    ['Producttype', 'Begindatum', 'Probleemnaam', 'Toelichting', 'Anatomische locatie', 'Lateraliteit', 'Zorgaanbieder.Organisatienaam'],
    ['Gehoorapparaat (SNOMED CT: 6012004)', '2007', 'Presbyacusis', 'Apparaat niet zichtbaar (diep in de gehooringang)', 'Oor', 'Rechts', 'Test Medisch Centrum'],
  ],

  'Vaccinaties': [
    ['Productcode', 'Vaccinatiedatum', 'Toediener', 'Zorgverlener.Achternaam', 'Toelichting'],
    ['Hepatitis A vaccin', '05/03/1999', '2007', 'Vaste Huisarts 1', 'Bezoek aan Guatemala.'],
  ],

 'Vitale functies, Bloeddruk': [
    ['Meetmethode', 'Manchettype', 'Meetlocatie', 'Systolische bloeddruk', 'Diastolische bloeddruk', 'Bloeddruk Datum Tijd','Houding','Uitvoerder'],
    ['Niet-invasief', 'Groot', 'Linker bovenarm', '108 mmHg', '56 mmHg', '07/02/13 08:53', 'Zittende positie', 'G.Z.M. de Wit'],
  ],
    
  'Vitale functies, Lichaamsgewicht': [
    ['Gewicht waarde','Gewicht Datum Tijd','Kleding', 'Uitvoerder'],
    ['72 kg', '08/02/2013', 'Lichte kleding/ondergoed', 'G.Z.M. de Wit'],
  ],

  'Vitale functies, Lichaamslengte': [
    ['Lengte waarde', 'Lengte Datum Tijd', 'Toelichting', 'Positie', 'Uitvoerder'],
    ['183 cm', '08/02/13 06:43', 'Met schoenen aan', 'Staande positie', 'G.Z.M. de Wit'],
  ],

  'Uitslagen': [
    ['Resultaat type', 'Testcode','Test Datum Tijd','Testuitslag','Testuitslag status', 'Referentie bovengrens', 'Referentie ondergrens','Interpretatie vlaggen', 'Monstermateriaal', 'Afname Datum Tijd'],
    ['Klinische chemie (SNOMED CT: 275711006)', 'Chloride (LOINC: 2069-3)', '23/05/12 12:00', '109 mmol/l', 'Definitief', '108 mmol/l', '99 mmol/l', 'Boven referentie-waarde', 'Bloed', '23/05/12 08:08'],
  ],

  'Behandeling': [
    ['Startdatum','Anatomische locatie', 'Lateraliteit', 'Type', 'Probleem naam', 'Toelichting','Producttype', 'ProductID', 'Toelichting', 'OrganisatieNaam', 'Specialisme'],
    ['05/06/2012', 'Knie', 'Links', 'Knie - prothese implantatie gewricht (SNOMED CT: 609588000)', 'Artropathie (SNOMED CT: 399269003)', 'Beperking bij het bewegen van het gewricht, drukpijn door slijtage van twee compartimenten van de linker knie.', 'Orthopedic surgical implants', '(01)05050474530799 (17)160131 (21)1148130801', 'Implantant, Gen 2 Tibia Insert 5/6-9mm', 'UMCG', 'Orthopedie'],
  ],
}

export default function HospitalScreen(): JSX.Element {
  const {t} = useTranslation()

  return (
    <Layout
      top={(
        <>
          <h1>{t('dossier.detail.hospital.title')}</h1>
          <p>{t('dossier.detail.hospital.intro')}</p>
        </>
      )}
      bottom={(
        <div className={clsx('container', styles.hospitalMain)}>
          <div className="row">
            <div className="col-12">
              <BackToDashboardButton />
            </div>
          </div>

          {Object.entries(tables).map(([key, table]) => (
            <div key={key} className="row">
              <div className="col-12">
                <h2>{key}</h2>
                <Table definition={table} />
              </div>
            </div>
          ))}
        </div>
      )}
    />
  )
}
