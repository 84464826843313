import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTE } from '../../../../config/route'
import { addDossier } from '../../../../reducers/dossier/dossier-reducer'
import {ReactComponent as MedMij} from '../medmij.svg'
import styles from './Permission.module.scss'

export default function Permission(): JSX.Element {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const saveDossier = (): void => {
    dispatch(addDossier({
      id: '5e',
      logo: 'doctor',
      title: 'Huisartsenpraktijk Azuurblauw',
      retrievedAt: 'Opgehaald op 22 februari 2022 - 13:45:10',
      link: ROUTE.DOSSIER_PHYSICIAN,
      new: true
    }))
    navigate(ROUTE.DOSSIER_DETAIL)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className={styles.permissionScreen}>
            <MedMij className={styles.logo} />
            <h1>{t('dossier.permission.title')}</h1>
            <p className={styles.content} dangerouslySetInnerHTML={{ __html: t('dossier.permission.content') }} />
            <div className={styles.grant}>
              <button className="btn btn-primary" onClick={saveDossier}>{t('dossier.permission.grant')}</button>
            </div>
            <div>
              <Link className="btn btn-ghost" to={ROUTE.DOSSIER_DETAIL}>{t('dossier.permission.deny')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
