import { useTranslation } from 'react-i18next'
import { ActionButton } from './ActionButton'
import styles from './Welcome.module.scss'

export function WelcomeTopPage(): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className={styles.title}>{t('welcome.top.title')}</div>

          <div className={styles.intro}>
            <h2>{t('welcome.top.intro')}</h2>
          </div>

          <div className={styles.button}>
            <ActionButton />
          </div>
        </div>
      </div>
    </div>
  )
}
