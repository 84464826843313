import React from 'react'
import {ReactComponent as ApprovalIcon} from './approved.svg'
import {ReactComponent as MasksIcon} from './masks.svg'
import {ReactComponent as DossierIcon} from './dossier.svg'
import {ReactComponent as PillsIcon} from './pills.svg'
import {ReactComponent as CalendarIcon} from './calendar.svg'
import {ReactComponent as LinkIcon} from './link.svg'
import {ReactComponent as DocumentsIcon} from './documents.svg'
import { Link } from 'react-router-dom'
import styles from './Tile.module.scss'
import { useTranslation } from 'react-i18next'

export enum TileIcons {
  MASKS = 'MASKS',
  DOSSIER = 'DOSSIER',
  PILLS = 'PILLS',
  CALENDAR = 'CALENDAR',
  LINK = 'LINK',
  DOCUMENTS = 'DOCUMENTS'
}

type IconSelector = keyof typeof TileIcons

export interface TileProps {
  approved: boolean,
  icon: IconSelector,
  title: string,
  note: string,
  interactive: boolean,
  link?: string
}

const icons: {[key in IconSelector]: JSX.Element} = {
  [TileIcons.MASKS]: <MasksIcon />,
  [TileIcons.DOSSIER]: <DossierIcon />,
  [TileIcons.PILLS]: <PillsIcon />,
  [TileIcons.CALENDAR]: <CalendarIcon />,
  [TileIcons.LINK]: <LinkIcon />,
  [TileIcons.DOCUMENTS]: <DocumentsIcon />
}

export function Tile({approved, icon, title, note, interactive, link}: TileProps) {
  const {t} = useTranslation()

  const tile = (
    <div className={styles.tile}>
      {approved && <ApprovalIcon className={styles.approved} />}

      <div className={styles.icon}>
        {icons[icon]}
      </div>

      <div className={styles.title}>
        {title}
      </div>

      <div className={styles.note}>
        {note}
      </div>

      {interactive && (
        <div className={styles.interactive}>
          {t('tiles.interactive')}
        </div>
      )}
    </div>
  )

  if (link !== undefined) {
    return (
      <Link to={link} className={styles.link}>{tile}</Link>
    )
  }

  return tile
}
