import {Fragment} from 'react'
import HeaderText from '../../Header/HeaderText'
import Layout from '../Layout'
import {ReactComponent as Hospital} from '../../../style/img/hospital.svg'
import {ReactComponent as Comment} from '../../../style/img/comment.svg'
import {ReactComponent as Document} from '../../../style/img/document.svg'
import {ReactComponent as Doctor} from '../../../style/img/doctor.svg'
import DossierImporter from '../../DossierImporter/DossierImporter'
import BackToDashboardButton from '../../Button/BackToDashboardButton'
import PageDetailInformation from '../../Widget/PageDetailInformation'
import {useTranslation} from 'react-i18next'
import {useDossiers} from '../../../hooks/Dossiers/dossiers'
import {Dossier} from '../../../reducers/dossier/dossier-reducer'
import IconList, {IconListItem} from '../../IconList/IconList'
import {Vaccination} from "../../../reducers/vaccination/vaccination-reducer";
import {useVaccinations} from "../../../hooks/Vaccinations/vaccinations";
import {ModalType} from "../../Modal/Modal";

const icons: {[key: string]: JSX.Element} = {
  'hospital': <Hospital />,
  'comment': <Comment />,
  'document': <Document />,
  'doctor': <Doctor />,
}

function dossierToListItem(dossier: Dossier): IconListItem {
  return {
    id: dossier.id,
    icon: icons[dossier.logo],
    title: dossier.title,
    new: dossier.new,
    link: dossier.link,
    contents: dossier.retrievedAt
  }
}

function vaccinationToListItem(vaccination: Vaccination): IconListItem {
  return {
    id: vaccination.id,
    icon: icons[vaccination.logo],
    title: vaccination.title,
    new: vaccination.new,
    contents: vaccination.retrievedAt,
    modal: vaccination.modal
  }
}

const ImportWidget = ({dossiers}: {dossiers: Array<Dossier>}): JSX.Element|null => {
  // '5e' is the dossier we added, probably Huisartsenpraktijk Azuurblauw. 
  // Its presence shows that the user added a dossier, so we don't need the input widget any more.
  if (dossiers.some(dossier => dossier.id === '5e')) {
    return null
  }

  return (
    <div className="row mt-5">
      <div className="col">
        <DossierImporter />
      </div>
    </div>
  )
}

const DossierPage = (): JSX.Element => {
  const { t } = useTranslation()
  const dossiers = useDossiers()
  const vaccinations = useVaccinations()

  return (
    <Fragment>
      <div className="container">
        <BackToDashboardButton />

        <ImportWidget dossiers={dossiers} />

        <PageDetailInformation
          title={t('dossier.header.title')}
          intro={t('dossier.header.intro')}
          questionList={t('dossier.header.questions', { returnObjects: true })}
        />
      
        <IconList items={dossiers.map(dossierToListItem)} />

        <PageDetailInformation
            title={'Vaccinatiegegevens'}
            intro={t('dossier.header.intro')}
            questionList={t('dossier.header.questions', { returnObjects: true })}
        />

        <IconList modal={ModalType.MODAL_TYPE_VACCINATION} items={vaccinations.map(vaccinationToListItem)} />

      </div>
    </Fragment>
  )
}

function DossierDetail(): JSX.Element {
  return <Layout top={<HeaderText />} bottom={<DossierPage />} />
}

export default DossierDetail
