export const ROUTE: {[key: string] : string} = {
  DOSSIER_DETAIL: '/dossier-detail',
  DOSSIER_LANDING: '/dossier/landing',
  DOSSIER_DIGID: '/dossier/digid',
  DOSSIER_DIGIDSTEPONE: '/dossier/digid/step-one',
  DOSSIER_DIGIDSTEPTWO: '/dossier/digid/step-two',
  DOSSIER_DIGIDSTEPTHREE: '/dossier/digid/step-three',
  DOSSIER_PERMISSION: '/dossier/permission',
  DOSSIER_PHYSICIAN: '/dossier/detail/physician',
  DOSSIER_GGZ: '/dossier/detail/ggz',
  DOSSIER_HOSPITAL: '/dossier/detail/hospital',
  DOSSIER_MEDICATION: '/dossier/detail/medication',
  DOCUMENTS: '/documents',
  LOGIN: '/',
  LOGIN2FA: '/login2fa',
  DASHBOARD: '/dashboard',
  APPOINTMENT: '/appointment'
}