import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from './ActionButton'
import styles from './Welcome.module.scss'

export function WelcomeTopFooter(): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className={styles.topFooter}>
      <div><h2>{t('welcome.top_footer_title')}</h2></div>
      <div className={styles.topFooterButton}>
        <ActionButton />
      </div>
    </div>
  )
}
