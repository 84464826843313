import {useTranslation} from 'react-i18next'
import BackToDashboardButton from '../../Button/BackToDashboardButton'
import IconList, {IconListItem} from '../../IconList/IconList'
import {ReactComponent as Documents} from './documents.svg'
import Layout from '../Layout'
import {ModalType} from "../../Modal/Modal";

interface ItemTranslation {
  title: string,
  contents: string
}

export default function DocumentScreen(): JSX.Element {
  const {t} = useTranslation()
  const translated: Array<ItemTranslation> = t('documents.items', {returnObjects: true})

  const items: Array<IconListItem> = translated.map((translation, index) => {
    return {
      ...translation,
      id: index.toString() + String.fromCharCode('a'.charCodeAt(0) + 1),
      icon: <Documents />,
      new: false,
      modal: true
    }
  })
  
  return <Layout
    top={<>
      <h1>{t('documents.title')}</h1>
      <p>{t('documents.intro')}</p>
    </>}
    bottom={(
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BackToDashboardButton />
          </div>
        </div>

        <div className="mt-4 row">
          <div className="col-12">
            <IconList items={items} modal={ModalType.MODAL_TYPE_DOCUMENT} />
          </div>
        </div>
      </div>
    )}
  />
}