import { ReactNode } from 'react'
import Logo from '../Logo/Logo'
import { ReactComponent as MainLogo } from '../../style/img/main-logo.svg'
import { ReactComponent as ViewMore } from '../../style/img/view-more.svg'
import { ReactComponent as Rectangle } from '../../style/img/rectangle.svg'

import styles from './Layout.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

interface LayoutProps {
  top: ReactNode
  bottom: ReactNode,
}
interface BaseLayoutProps extends LayoutProps {
  callToAction: boolean
}

interface WelcomeLayoutProps extends LayoutProps{
  middle: ReactNode
  footerTop: ReactNode
  footerBottom: ReactNode
}

function BaseLayout({top, bottom, callToAction}: BaseLayoutProps): JSX.Element {
  const {t} = useTranslation()

  return (
    <div className={styles.page}>
      <div className={styles.dashboardHeader}>
        <Logo />
        <div className={clsx('container', styles.top)}>
          <div className="row">
            <div className={clsx(styles.callToActionColumn, 'col-12')}>
              {callToAction && (
                <a href="https://www.digitalezorggids.nl/kies-je-pgo/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={clsx('btn btn-primary', styles.chooseAPgo)}
                >{
                  t('header.choose_a_pgo')
                }</a>
              )}
            </div>

            {top}
          </div>
        </div>
        <div
          className={`position-absolute start-50 translate-middle ${styles.viewMoreDashboard}`}
        >
          <ViewMore />
        </div>
      </div>
      <div>{bottom}</div>
    </div>
  )
}

export function LoginLayout({top, bottom}: LayoutProps): JSX.Element {
  return <BaseLayout
    top={<div className="col-12">{top}</div>}
    bottom={bottom}
    callToAction={false}
  />
}

export function DashboardLayout({ top, bottom }: LayoutProps): JSX.Element {
  return <BaseLayout
    top={<div className="col-12 col-lg-6 offset-lg-3">{top}</div>}
    bottom={bottom}
    callToAction={true}
  />
}

export function Layout({ top, bottom }: LayoutProps): JSX.Element {
  return <BaseLayout
    top={<div className="col-12">{top}</div>}
    bottom={bottom}
    callToAction={true}
  />
}

export function WelcomeLayout({ top, middle, bottom, footerTop, footerBottom }: WelcomeLayoutProps): JSX.Element {
  return (
    <div className={styles.center}>
      <div className={styles.wrapHeader}>
        <div className={styles.header}>
          <div className={styles.logoTop}><MainLogo /></div>
          <div>{top}</div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div>{middle}</div>
          </div>
        </div>
      </div>

      <div className={styles.wrapHeader}>
        <div className={styles.middleBottom}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>{bottom}</div>
                <div
                  className={`position-absolute start-50 translate-middle ${styles.rectangle}`}
                >
                  <Rectangle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.footer}>
              <div className={styles.footerTop}>{footerTop}</div>
              {footerBottom}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
