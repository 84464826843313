import clsx from 'clsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { addMedication, Medication } from '../../reducers/medication/medication-reducer'
import styles from './MedicationForm.module.scss'

interface Option {
  label: string,
  value: string,
  disabled?: boolean,
  dispatchValue: Medication
}

const medicationOptions: Array<Option> = [
  {
    label: 'Paracetamol - 250 mg',
    value: '250',
    disabled: true,
    dispatchValue: [
      'Paracetamol - 250 mg <span class="newItem">Nieuw</span>',
      '22-03-2022',
      '12-12-2023',
      'Max 6 st. per dag Oraal gebruik',
      'Zelf'
    ]
  },
  {
    label: 'Paracetamol - 500 mg',
    value: '500',
    dispatchValue: [
      'Paracetamol - 500 mg <span class="newItem">Nieuw</span>',
      '22-03-2022',
      '27-03-2022',
      'Max 6 st. per dag Oraal gebruik',
      'Zelf'
    ]
  }
]

export default function MedicationForm(): JSX.Element {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [selection, setSelection] = useState<Option|null>(null)
  const active = selection !== null ? '' : 'inactive'

  const handleSubmission: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()

    if (selection !== null) {
      dispatch(addMedication(selection?.dispatchValue))
    }
  }

  const hiddenPart = (
    <div className="row">
      <div className="col-sm-6 col-lg-2">
        <label>
          <div className={styles.formLabel}>Startdatum</div>
          <input type="text" className="form-control" value={medicationOptions[1].dispatchValue[1]} readOnly />
        </label>
      </div>
      <div className="col-sm-6 col-lg-2">
        <label>
          <div className={styles.formLabel}>Einddatum</div>
          <input type="text" className="form-control" value={medicationOptions[1].dispatchValue[2]} readOnly />
        </label>
      </div>
      <div className="col-sm-6 col-md-12 col-lg-4">
        <label>
          <div className={styles.formLabel}>Dosering</div>
          <input type="text" className="form-control" value={medicationOptions[1].dispatchValue[3]} readOnly />
        </label>
      </div>
    </div>
  )

  return (
    <form className={styles.medicationForm} onSubmit={handleSubmission}>
      <h2>{t('dossier.detail.medication-form.add')}</h2>

      <div className="form-group">
        <label>
          <div className={styles.formLabel}>Medicijn</div>

          <div className={styles.formInput}>
            <Select
              options={medicationOptions}
              placeholder={t('dossier.detail.medication-form.placeholder')}
              value={selection}
              onChange={setSelection}
              isClearable
              isOptionDisabled={(option) => option.disabled === true}
              />
          </div>
        </label>
      </div>

      {selection !== null && hiddenPart}

      <div className="row">
        <div className="col-12">
          <button type="submit" className={clsx('btn btn-primary', active)}>
            {t('dossier.detail.medication-form.submit')}
          </button>
        </div>
      </div>
    </form>
  )
}
