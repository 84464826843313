import * as React from 'react'
import {ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import styles from './IconList.module.scss'
import Modal, {ModalType} from '../Modal/Modal'

export interface IconListItem {
    id: string,
    icon: ReactNode,
    title: string,
    link?: string,
    new: boolean,
    contents: ReactNode,
    modal?: boolean
}

interface IconListProps {
    items: Array<IconListItem>,
    modal?: ModalType
}

export default function IconList({items, modal}: IconListProps): JSX.Element {
    const {t} = useTranslation()
    const [modalOpen, setModalOpen] = useState(false)

    const openModal = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div className={styles.list}>
                {items.map((item) => (
                    <div
                        className={`mt-3 ${styles.listItem} ${
                            item.modal ? styles.listItemModal : ''
                        } `}
                        title={item.title}
                        key={item.id}
                        onClick={item.modal ? openModal : undefined}
                    >
                        <div className={styles.listItemLogo}>{item.icon}</div>
                        <div className={styles.listContents}>
                            <div className={styles.listItemTitle}>
                                {item.link
                                    ? <Link to={item.link}>{item.title}</Link>
                                    : item.title
                                }
                                {item.new && <span className="newItem">{t('icon_list.new')}</span>}
                            </div>
                            <div className={styles.listItemInfo}>
                                {item.contents}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {modalOpen && modal !== undefined && <Modal type={modal} closeModal={closeModal} />}
        </>
    )
}