import { ReactComponent as Calendar } from '../../../style/img/calendar.svg'
import { useTranslation } from 'react-i18next'
import Table from '../../Table/Table'
import IconList, { IconListItem } from '../../IconList/IconList'

const ApointmentList = (): JSX.Element => {
  const { t } = useTranslation()

  const getTableConfig = (key: string): Array<string> => {
    return t(key, {
      returnObjects: true,
      interpolation: { escapeValue: false },
    })
  }

  const items: Array<IconListItem> = [
    {
      id: '1',
      new: false,
      icon: <Calendar />,
      title: 'Huisartsenpraktijk Azuurblauw',
      contents: <Table definition={[
        getTableConfig('appointment.tables.header'),
        getTableConfig('appointment.tables.column_first'),
      ]} />,
    },
    {
      id: '2',
      new: false,
      icon: <Calendar />,
      title: 'Ziekenhuis Utrecht',
      contents: <Table definition={[
        getTableConfig('appointment.tables.header'),
        getTableConfig('appointment.tables.column_last'),
      ]} />,
    },
  ]

  return (
    <div className="container mt-5">
      <IconList items={items} />
    </div>
  )
}

export default ApointmentList
