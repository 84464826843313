import * as React from 'react'
import { useTranslation } from 'react-i18next'
import style from './LoginFormBottom.module.scss'
import infographic from '../../style/img/infographic.svg'

const LoginFormBottom = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <div className={style.formWidth}>
            <div className="mt-5">
              <h2>{t('login.question')}</h2>
            </div>
            <div className="mt-5">
              <div><img src={infographic} /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginFormBottom