import React from 'react'
import LoginForm2FA from '../Form/LoginForm2FA'
import LoginFormBottom from '../Form/LoginFormBottom'
import { LoginLayout } from './Layout'

function Login2FA(): JSX.Element {
  return <LoginLayout top={<LoginForm2FA />} bottom={<LoginFormBottom />} />
}

export default Login2FA
