import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Welcome.module.scss'
import { ReactComponent as CheckIcon } from '../../../style/img/check.svg'

export function WelcomeBottomPage(): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className={styles.bottom}>
      <div className={styles.bottomTitle}><h2>{t('welcome.bottom.title_first')}</h2></div>
      <div className={styles.text}>{t('welcome.bottom.text_first')}</div>
      <div className={styles.bottomTitle}><h2>{t('welcome.bottom.title_second')}</h2></div>
      <div className={styles.text}>{t('welcome.bottom.text_second')}</div>
      <div className={styles.bottomTitle}><CheckIcon /></div>
      <div className={`mt-4 ${styles.text}`}>{t('welcome.bottom.text_third')}</div>
    </div>
  )
}
