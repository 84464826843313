import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE } from '../../config/route'
import style from './LoginForm2FA.module.scss'
import { ReactComponent as QACode } from '../../style/img/qa-code.svg'
import LoginForm from './LoginForm'
import LoginSteps from './LoginSteps'
import OTP from '../Input/OTP'
import LoginFormAction from './LoginFormAction'
import { Link } from 'react-router-dom'

enum METHOD {
  NONE,
  QR_CODE,
  OTP,
}

const LoginForm2FA = (): JSX.Element => {
  const { t } = useTranslation()
  const [showMethod, setShowMethod] = useState(METHOD.NONE)
  const [showOTP, setShowOTP] = useState(false)

  return (
    <form className={style.formWidth}>
      <div className="mb-3">
        <h1>{t('login')}</h1>
      </div>

      <div className="mb-4">
        <p>{t('login2fa.title')}</p>
      </div>

      <div className={`mt-3 ${style.authenticator}`}>
        <div className={style.content2fa}>
          <div className="p-2">
            <input
              className="form-check-input"
              type="radio"
              name="gridRadios"
              id="yivi-app"
              checked={showMethod === METHOD.QR_CODE}
              onChange={() => setShowMethod(METHOD.QR_CODE)}
            />
            <label className="form-check-label" htmlFor="yivi-app">
              {t('login2fa.yivi_app')}
            </label>
          </div>
        </div>

        <div
          className={`${showMethod !== METHOD.QR_CODE ? 'd-none' : ''} ${
            style.area2fa
          }`}
        >
          <div className={`mt-2 ${style.area2faContent}`}>
            <div className={`${style.area2faCode}`}>
              <QACode />
            </div>
            <Link className="btn btn-primary" to={ROUTE.DASHBOARD}>{t('login.text')}</Link>
            <LoginSteps text='login2fa.step_login'/>
          </div>
        </div>
      </div>

      <div className={`${style.authenticator}`}>
        <div className={`${style.content2fa}`}>
          <div className="p-2">
            <input
              className="form-check-input"
              type="radio"
              name="gridRadios"
              id="otp-app"
              checked={showMethod === METHOD.OTP}
              onChange={() => setShowMethod(METHOD.OTP)}
            />
            <label className="form-check-label" htmlFor="otp-app">
              {t('login2fa.google')}
            </label>
          </div>
        </div>

        <div
          className={`${showMethod !== METHOD.OTP ? 'd-none' : ''} ${
            style.area2fa
          }`}
        >
          <div className={`mt-2 ${style.area2faContent}`}>
            <LoginForm />
            {showOTP ? (
              <OTP />
            ) : (
              <LoginFormAction
                onClick={() => {
                  setShowOTP(true)
                }}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default LoginForm2FA
