interface TableProps {
  definition: Array<Array<string>>,
  noHeader?: boolean
}

export default function Table({definition, noHeader}: TableProps): JSX.Element {
  return (
    <div className='table-responsive'>
      <table>
        {!noHeader && (
          <thead>
            <tr>
              {definition[0].map((heading, index) => <th key={`0-${index}`}>{heading}</th>)}
            </tr>
          </thead>
        )}
        <tbody>
          {definition.slice(noHeader ? 0 : 1).map((row, rowIndex) => (
            <tr key={`row-${rowIndex}`}>
              {row.map((column, colIndex) => <td key={`${rowIndex}-${colIndex}`} dangerouslySetInnerHTML={{__html: column}} />)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

Table.defaultProps = {
  noHeader: false
}