import { ReactComponent as Eye } from '../../style/img/eye.svg'
import style from './LoginForm.module.scss'

const LoginForm = (): JSX.Element => {
  return (
    <div className={style.formWidth}>
      <div className="mb-3">
        <input
          type="email"
          className="form-control"
          defaultValue="voorbeeld@mijnmail.com"
        />
      </div>
      <div className="mb-2">
        <input
          type="password"
          className="form-control"
          defaultValue="111111111111"
        />
        <div className={style.inputGroup}>
          <Eye />
        </div>
      </div>
    </div>
  )
}

export default LoginForm
