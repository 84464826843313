import React from 'react'
import { useTiles } from '../../hooks/Tiles/tiles'
import { Tile } from './Tile'
import styles from './TileList.module.scss'

export function TileList(): JSX.Element {
  return (
    <div className={styles.tileList}>
      {useTiles().map(tile => {
        return <Tile key={tile.title} {...tile} />
      })}
    </div>
  )
}