import { useTranslation } from 'react-i18next'
import { TileProps, TileIcons } from '../../components/Tile/Tile'
import { ROUTE } from '../../config/route'

export function useTiles(): Array<TileProps> {
  const {t} = useTranslation()

  return [
    {
      approved: false,
      icon: TileIcons.MASKS,
      title: t('tiles.diary.title'),
      note: t('tiles.diary.note'),
      interactive: false
    },
    {
      approved: true,
      icon: TileIcons.DOSSIER,
      title: t('tiles.dossier.title'),
      note: t('tiles.dossier.note'),
      link: ROUTE.DOSSIER_DETAIL,
      interactive: true
    },
    {
      approved: true,
      icon: TileIcons.PILLS,
      title: t('tiles.medication.title'),
      note: t('tiles.medication.note'),
      link: ROUTE.DOSSIER_MEDICATION,
      interactive: true
    },
    {
      approved: true,
      icon: TileIcons.CALENDAR,
      title: t('tiles.calendar.title'),
      note: t('tiles.calendar.note'),
      link: ROUTE.APPOINTMENT,
      interactive: false
    },
    {
      approved: false,
      icon: TileIcons.LINK,
      title: t('tiles.devices.title'),
      note: t('tiles.devices.note'),
      interactive: false
    },
    {
      approved: true,
      icon: TileIcons.DOCUMENTS,
      title: t('tiles.documents.title'),
      note: t('tiles.documents.note'),
      link: ROUTE.DOCUMENTS,
      interactive: false
    },
  ]
}
