import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTE } from '../../config/route'
import style from './Logo.module.scss'

const Logo = (): JSX.Element => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onLogoClick = () => {
    const except = [ROUTE.LOGIN, ROUTE.LOGIN2FA]
    if (!except.includes(pathname)) {
      navigate(ROUTE.DASHBOARD)
    }
  }

  return (
    <div className={style.wrapper}>
      <svg
        onClick={onLogoClick}
        cursor="pointer"
        fontFamily="Quicksand"
        width="200"
        height="147"
        viewBox="0 0 200 147"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="78.5" cy="25.5" r="121.5" fill="white" />
      </svg>
      <svg
        onClick={onLogoClick}
        cursor="pointer"
        width="108"
        height="63"
        style={{ position: 'absolute', left: 36, top: 30 }}
        viewBox="0 0 108 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0652 22.9043C17.9923 22.9043 19.7897 23.4635 21.4574 24.582C23.1621 25.7004 24.5333 27.2103 25.571 29.1116C26.6086 31.013 27.1275 33.138 27.1275 35.4867C27.1275 37.7981 26.6086 39.9045 25.571 41.8058C24.5333 43.7072 23.1621 45.2357 21.4574 46.3914C19.7897 47.5099 17.9923 48.0691 16.0652 48.0691H5.83685L6.39274 47.0625V58.6383C6.39274 59.6076 6.09627 60.4278 5.50332 61.0988C4.91037 61.7326 4.13212 62.0495 3.16858 62.0495C2.24209 62.0495 1.48238 61.7326 0.889425 61.0988C0.296475 60.4278 0 59.6076 0 58.6383V26.3155C0 25.3462 0.315005 24.5447 0.945014 23.9109C1.61208 23.2398 2.42739 22.9043 3.39093 22.9043H16.0652ZM16.0652 41.694C16.7694 41.694 17.455 41.3958 18.122 40.7993C18.7891 40.2028 19.345 39.4385 19.7897 38.5065C20.2344 37.5372 20.4568 36.5306 20.4568 35.4867C20.4568 34.4055 20.2344 33.3989 19.7897 32.4669C19.345 31.5349 18.7891 30.7893 18.122 30.2301C17.455 29.6336 16.7694 29.3353 16.0652 29.3353H5.72567L6.39274 28.3287V42.5887L5.78126 41.694H16.0652Z"
          fill="url(#paint0_linear_386_1145)"
        />
        <path
          d="M51.839 62.6087C49.0225 62.6087 46.3912 62.0868 43.9453 61.0429C41.4994 59.999 39.35 58.5637 37.497 56.7369C35.644 54.8729 34.1802 52.7292 33.1055 50.3059C32.0678 47.8827 31.549 45.273 31.549 42.4769C31.549 39.6808 32.0678 37.0711 33.1055 34.6479C34.1802 32.2246 35.644 30.0996 37.497 28.2728C39.35 26.4087 41.4994 24.9548 43.9453 23.9109C46.3912 22.867 49.0225 22.3451 51.839 22.3451C53.5808 22.3451 55.267 22.5315 56.8976 22.9043C58.5282 23.2771 60.0291 23.8363 61.4003 24.582C61.9562 24.8802 62.3638 25.2903 62.6233 25.8122C62.9197 26.2969 63.068 26.8002 63.068 27.3221C63.068 28.2169 62.7715 29.0184 62.1785 29.7268C61.5856 30.4351 60.8259 30.7893 59.8994 30.7893C59.6029 30.7893 59.2879 30.752 58.9544 30.6774C58.6579 30.6029 58.3614 30.491 58.065 30.3419C57.1385 29.9318 56.1564 29.6149 55.1187 29.3912C54.0811 29.1675 52.9878 29.0557 51.839 29.0557C49.4301 29.0557 47.2251 29.6708 45.2239 30.9011C43.2597 32.0941 41.6847 33.7158 40.4988 35.7663C39.35 37.7795 38.7755 40.0163 38.7755 42.4769C38.7755 44.9002 39.35 47.137 40.4988 49.1875C41.6847 51.238 43.2597 52.8783 45.2239 54.1086C47.2251 55.3016 49.4301 55.8981 51.839 55.8981C52.9508 55.8981 54.1737 55.7863 55.5079 55.5626C56.842 55.3389 57.9353 55.0406 58.7876 54.6678L58.2873 56.3455V46.7829L59.2323 47.6217H53.0619C52.0984 47.6217 51.2831 47.3048 50.616 46.671C49.986 46.0373 49.671 45.2357 49.671 44.2664C49.671 43.2971 49.986 42.4955 50.616 41.8618C51.2831 41.228 52.0984 40.9111 53.0619 40.9111H62.1785C63.1421 40.9111 63.9389 41.2466 64.5689 41.9177C65.1989 42.5515 65.5139 43.353 65.5139 44.3223V56.1777C65.5139 56.9233 65.3286 57.5385 64.958 58.0231C64.6245 58.5078 64.2353 58.8992 63.7906 59.1975C62.0859 60.2414 60.2144 61.0802 58.1761 61.714C56.1749 62.3105 54.0625 62.6087 51.839 62.6087Z"
          fill="url(#paint1_linear_386_1145)"
        />
        <path
          d="M108 42.4769C108 45.273 107.537 47.8827 106.61 50.3059C105.684 52.7292 104.368 54.8729 102.663 56.7369C100.996 58.5637 98.9946 59.999 96.6598 61.0429C94.3622 62.0868 91.8421 62.6087 89.0997 62.6087C86.3573 62.6087 83.8373 62.0868 81.5396 61.0429C79.2419 59.999 77.2407 58.5637 75.536 56.7369C73.8683 54.8729 72.5712 52.7292 71.6448 50.3059C70.7183 47.8827 70.255 45.273 70.255 42.4769C70.255 39.6808 70.7183 37.0711 71.6448 34.6479C72.5712 32.2246 73.8683 30.0996 75.536 28.2728C77.2407 26.4087 79.2419 24.9548 81.5396 23.9109C83.8373 22.867 86.3573 22.3451 89.0997 22.3451C91.8421 22.3451 94.3622 22.867 96.6598 23.9109C98.9946 24.9548 100.996 26.4087 102.663 28.2728C104.368 30.0996 105.684 32.2246 106.61 34.6479C107.537 37.0711 108 39.6808 108 42.4769ZM100.773 42.4769C100.773 39.9791 100.273 37.7236 99.2725 35.7104C98.2719 33.6599 96.9007 32.0382 95.1589 30.8452C93.4171 29.6522 91.3974 29.0557 89.0997 29.0557C86.802 29.0557 84.7823 29.6522 83.0405 30.8452C81.2987 32.0382 79.9275 33.6413 78.9269 35.6545C77.9634 37.6676 77.4816 39.9418 77.4816 42.4769C77.4816 44.9747 77.9634 47.2489 78.9269 49.2993C79.9275 51.3125 81.2987 52.9156 83.0405 54.1086C84.7823 55.3016 86.802 55.8981 89.0997 55.8981C91.3974 55.8981 93.4171 55.3016 95.1589 54.1086C96.9007 52.9156 98.2719 51.3125 99.2725 49.2993C100.273 47.2489 100.773 44.9747 100.773 42.4769Z"
          fill="url(#paint2_linear_386_1145)"
        />
        <path
          d="M8.78688 0.113419C9.02176 0.113419 9.22376 0.193757 9.39288 0.354433C9.57139 0.505658 9.66065 0.699415 9.66065 0.935704C9.66065 1.05857 9.63246 1.18617 9.57609 1.31849L6.09512 9.51299C6.01056 9.70202 5.88842 9.84379 5.7287 9.93831C5.57837 10.0234 5.42335 10.0659 5.26363 10.0659C5.11331 10.0565 4.96768 10.0092 4.82675 9.92413C4.68582 9.82962 4.57777 9.6973 4.50261 9.52717L1.02164 1.30431C0.993451 1.24761 0.97466 1.1909 0.965265 1.13419C0.95587 1.06803 0.951172 1.00659 0.951172 0.949882C0.951172 0.675786 1.04982 0.467852 1.24712 0.326078C1.44443 0.174854 1.63233 0.0992416 1.81084 0.0992416C2.16787 0.0992416 2.42624 0.283547 2.58596 0.652157L5.67233 7.95348L5.17907 7.96766L8.01177 0.652157C8.17149 0.292998 8.42986 0.113419 8.78688 0.113419Z"
          fill="#384B96"
        />
        <path
          d="M22.7388 5.10384C22.7388 5.81271 22.6214 6.47432 22.3865 7.08867C22.1516 7.70302 21.8181 8.24648 21.3859 8.71906C20.9631 9.18219 20.4558 9.54607 19.8639 9.81072C19.2814 10.0754 18.6425 10.2077 17.9472 10.2077C17.252 10.2077 16.6131 10.0754 16.0306 9.81072C15.4481 9.54607 14.9407 9.18219 14.5085 8.71906C14.0857 8.24648 13.7569 7.70302 13.522 7.08867C13.2871 6.47432 13.1697 5.81271 13.1697 5.10384C13.1697 4.39497 13.2871 3.73337 13.522 3.11901C13.7569 2.50466 14.0857 1.96592 14.5085 1.5028C14.9407 1.03022 15.4481 0.661609 16.0306 0.396965C16.6131 0.132322 17.252 0 17.9472 0C18.6425 0 19.2814 0.132322 19.8639 0.396965C20.4558 0.661609 20.9631 1.03022 21.3859 1.5028C21.8181 1.96592 22.1516 2.50466 22.3865 3.11901C22.6214 3.73337 22.7388 4.39497 22.7388 5.10384ZM20.9068 5.10384C20.9068 4.47059 20.7799 3.89877 20.5262 3.38838C20.2726 2.86855 19.9249 2.4574 19.4834 2.15495C19.0418 1.8525 18.5297 1.70128 17.9472 1.70128C17.3647 1.70128 16.8527 1.8525 16.4111 2.15495C15.9695 2.4574 15.6219 2.86382 15.3682 3.37421C15.1239 3.88459 15.0018 4.46113 15.0018 5.10384C15.0018 5.73709 15.1239 6.31364 15.3682 6.83347C15.6219 7.34386 15.9695 7.75028 16.4111 8.05273C16.8527 8.35518 17.3647 8.5064 17.9472 8.5064C18.5297 8.5064 19.0418 8.35518 19.4834 8.05273C19.9249 7.75028 20.2726 7.34386 20.5262 6.83347C20.7799 6.31364 20.9068 5.73709 20.9068 5.10384Z"
          fill="#384B96"
        />
        <path
          d="M36.1101 5.10384C36.1101 5.81271 35.9927 6.47432 35.7578 7.08867C35.5229 7.70302 35.1894 8.24648 34.7572 8.71906C34.3344 9.18219 33.8271 9.54607 33.2352 9.81072C32.6526 10.0754 32.0138 10.2077 31.3185 10.2077C30.6232 10.2077 29.9844 10.0754 29.4019 9.81072C28.8193 9.54607 28.312 9.18219 27.8798 8.71906C27.457 8.24648 27.1282 7.70302 26.8933 7.08867C26.6584 6.47432 26.541 5.81271 26.541 5.10384C26.541 4.39497 26.6584 3.73337 26.8933 3.11901C27.1282 2.50466 27.457 1.96592 27.8798 1.5028C28.312 1.03022 28.8193 0.661609 29.4019 0.396965C29.9844 0.132322 30.6232 0 31.3185 0C32.0138 0 32.6526 0.132322 33.2352 0.396965C33.8271 0.661609 34.3344 1.03022 34.7572 1.5028C35.1894 1.96592 35.5229 2.50466 35.7578 3.11901C35.9927 3.73337 36.1101 4.39497 36.1101 5.10384ZM34.278 5.10384C34.278 4.47059 34.1512 3.89877 33.8975 3.38838C33.6439 2.86855 33.2962 2.4574 32.8546 2.15495C32.4131 1.8525 31.901 1.70128 31.3185 1.70128C30.736 1.70128 30.2239 1.8525 29.7824 2.15495C29.3408 2.4574 28.9932 2.86382 28.7395 3.37421C28.4952 3.88459 28.3731 4.46113 28.3731 5.10384C28.3731 5.73709 28.4952 6.31364 28.7395 6.83347C28.9932 7.34386 29.3408 7.75028 29.7824 8.05273C30.2239 8.35518 30.736 8.5064 31.3185 8.5064C31.901 8.5064 32.4131 8.35518 32.8546 8.05273C33.2962 7.75028 33.6439 7.34386 33.8975 6.83347C34.1512 6.31364 34.278 5.73709 34.278 5.10384Z"
          fill="#384B96"
        />
        <path
          d="M41.4202 10.0659C41.1571 10.0659 40.9223 9.98557 40.7156 9.82489C40.5089 9.65476 40.4055 9.44683 40.4055 9.20109V1.00659C40.4055 0.76085 40.4854 0.557642 40.6451 0.396965C40.8142 0.226837 41.0209 0.141774 41.2652 0.141774H45.324C45.8125 0.141774 46.2682 0.274095 46.691 0.538739C47.1232 0.793931 47.4708 1.15309 47.7339 1.61622C47.9969 2.06989 48.1285 2.59445 48.1285 3.1899C48.1285 3.54906 48.0533 3.89877 47.903 4.23902C47.7621 4.57928 47.5648 4.88645 47.3111 5.16055C47.0668 5.43464 46.7897 5.6473 46.4796 5.79853L46.4937 5.47245C46.7568 5.61422 46.9729 5.78435 47.142 5.98284C47.3205 6.17187 47.4567 6.3798 47.5507 6.60664C47.654 6.82402 47.7151 7.06031 47.7339 7.3155C47.7715 7.55179 47.7949 7.75973 47.8043 7.93931C47.8231 8.11889 47.8513 8.27011 47.8889 8.39298C47.9359 8.51585 48.0204 8.61037 48.1426 8.67653C48.3493 8.7994 48.4761 8.97898 48.5231 9.21527C48.5795 9.4421 48.5278 9.64531 48.3681 9.82489C48.2647 9.94776 48.1332 10.0281 47.9735 10.0659C47.8137 10.0943 47.654 10.0943 47.4943 10.0659C47.3346 10.0281 47.203 9.98084 47.0997 9.92413C46.94 9.82962 46.7803 9.69257 46.6205 9.51299C46.4608 9.32396 46.3293 9.07349 46.2259 8.76159C46.132 8.44969 46.085 8.048 46.085 7.55652C46.085 7.39584 46.0568 7.24934 46.0004 7.11702C45.9441 6.97525 45.8642 6.8571 45.7609 6.76259C45.6575 6.65862 45.526 6.58301 45.3663 6.53575C45.2065 6.47904 45.0186 6.45069 44.8025 6.45069H41.9558L42.1671 6.12461V9.20109C42.1671 9.44683 42.1014 9.65476 41.9698 9.82489C41.8383 9.98557 41.6551 10.0659 41.4202 10.0659ZM41.9698 4.99042H45.2535C45.4226 4.99042 45.587 4.91481 45.7468 4.76358C45.9065 4.60291 46.038 4.39025 46.1414 4.1256C46.2447 3.85151 46.2964 3.55378 46.2964 3.23243C46.2964 2.81656 46.1837 2.47158 45.9582 2.19749C45.7421 1.91394 45.5072 1.77217 45.2535 1.77217H41.998L42.1671 1.31849V5.33068L41.9698 4.99042Z"
          fill="#384B96"
        />
        <path
          d="M57.1695 0.141774C58.0714 0.141774 58.7432 0.354433 59.1848 0.779753C59.6264 1.20507 59.8471 1.8336 59.8471 2.66534C59.8471 3.10956 59.7391 3.5018 59.523 3.84206C59.3069 4.17286 58.9875 4.43278 58.5647 4.62181C58.1419 4.80139 57.6204 4.89118 57.0004 4.89118L57.0567 4.2532C57.348 4.2532 57.6815 4.29573 58.0573 4.3808C58.4331 4.45641 58.7949 4.59818 59.1425 4.80612C59.4995 5.0046 59.7908 5.28815 60.0163 5.65676C60.2511 6.01592 60.3686 6.47904 60.3686 7.04614C60.3686 7.66994 60.2652 8.18032 60.0585 8.57729C59.8612 8.97425 59.5982 9.28143 59.2693 9.49881C58.9405 9.7162 58.5882 9.86742 58.2124 9.95249C57.8365 10.0281 57.4748 10.0659 57.1272 10.0659H53.618C53.3738 10.0659 53.1671 9.98557 52.9979 9.82489C52.8382 9.65476 52.7584 9.44683 52.7584 9.20109V1.00659C52.7584 0.76085 52.8382 0.557642 52.9979 0.396965C53.1671 0.226837 53.3738 0.141774 53.618 0.141774H57.1695ZM56.9158 1.8147H54.4072L54.5905 1.58786V4.15396L54.4213 4.02636H56.9581C57.2305 4.02636 57.4748 3.93657 57.6909 3.75699C57.907 3.57741 58.0151 3.3175 58.0151 2.97724C58.0151 2.57082 57.9117 2.27782 57.705 2.09824C57.5077 1.90921 57.2446 1.8147 56.9158 1.8147ZM57.0285 5.69929H54.4636L54.5905 5.58587V8.57729L54.4495 8.43551H57.1272C57.5594 8.43551 57.9023 8.32209 58.156 8.09526C58.4097 7.85897 58.5365 7.50926 58.5365 7.04614C58.5365 6.62082 58.4519 6.31364 58.2828 6.12461C58.1137 5.93558 57.9117 5.81743 57.6768 5.77017C57.4419 5.72292 57.2258 5.69929 57.0285 5.69929Z"
          fill="#384B96"
        />
        <path
          d="M65.5305 0.141774H70.2516C70.4959 0.141774 70.6979 0.222111 70.8576 0.382788C71.0267 0.534013 71.1113 0.732496 71.1113 0.978236C71.1113 1.21453 71.0267 1.40828 70.8576 1.55951C70.6979 1.70128 70.4959 1.77217 70.2516 1.77217H66.3056L66.4324 1.53115V4.29573L66.3197 4.18231H69.6174C69.8617 4.18231 70.0637 4.26265 70.2234 4.42333C70.3926 4.57455 70.4771 4.77303 70.4771 5.01878C70.4771 5.25506 70.3926 5.44882 70.2234 5.60005C70.0637 5.74182 69.8617 5.81271 69.6174 5.81271H66.362L66.4324 5.69929V8.56311L66.3197 8.43551H70.2516C70.4959 8.43551 70.6979 8.52058 70.8576 8.69071C71.0267 8.85138 71.1113 9.04041 71.1113 9.2578C71.1113 9.49409 71.0267 9.68784 70.8576 9.83907C70.6979 9.99029 70.4959 10.0659 70.2516 10.0659H65.5305C65.2862 10.0659 65.0795 9.98557 64.9104 9.82489C64.7507 9.65476 64.6708 9.44683 64.6708 9.20109V1.00659C64.6708 0.76085 64.7507 0.557642 64.9104 0.396965C65.0795 0.226837 65.2862 0.141774 65.5305 0.141774Z"
          fill="#384B96"
        />
        <path
          d="M76.2731 0.141774H80.9942C81.2385 0.141774 81.4405 0.222111 81.6002 0.382788C81.7694 0.534013 81.8539 0.732496 81.8539 0.978236C81.8539 1.21453 81.7694 1.40828 81.6002 1.55951C81.4405 1.70128 81.2385 1.77217 80.9942 1.77217H77.0482L77.175 1.53115V4.29573L77.0623 4.18231H80.3601C80.6043 4.18231 80.8063 4.26265 80.9661 4.42333C81.1352 4.57455 81.2197 4.77303 81.2197 5.01878C81.2197 5.25506 81.1352 5.44882 80.9661 5.60005C80.8063 5.74182 80.6043 5.81271 80.3601 5.81271H77.1046L77.175 5.69929V8.56311L77.0623 8.43551H80.9942C81.2385 8.43551 81.4405 8.52058 81.6002 8.69071C81.7694 8.85138 81.8539 9.04041 81.8539 9.2578C81.8539 9.49409 81.7694 9.68784 81.6002 9.83907C81.4405 9.99029 81.2385 10.0659 80.9942 10.0659H76.2731C76.0288 10.0659 75.8221 9.98557 75.653 9.82489C75.4933 9.65476 75.4134 9.44683 75.4134 9.20109V1.00659C75.4134 0.76085 75.4933 0.557642 75.653 0.396965C75.8221 0.226837 76.0288 0.141774 76.2731 0.141774Z"
          fill="#384B96"
        />
        <path
          d="M91.5959 8.40716C91.8402 8.40716 92.0422 8.4875 92.2019 8.64817C92.371 8.7994 92.4556 8.99788 92.4556 9.24362C92.4556 9.47991 92.371 9.67839 92.2019 9.83907C92.0422 9.99029 91.8402 10.0659 91.5959 10.0659H87.0157C86.7714 10.0659 86.5647 9.98557 86.3956 9.82489C86.2359 9.65476 86.156 9.44683 86.156 9.20109V1.00659C86.156 0.76085 86.2406 0.557642 86.4097 0.396965C86.5788 0.226837 86.7949 0.141774 87.058 0.141774C87.2835 0.141774 87.4808 0.226837 87.6499 0.396965C87.8284 0.557642 87.9176 0.76085 87.9176 1.00659V8.64817L87.6076 8.40716H91.5959Z"
          fill="#384B96"
        />
        <path
          d="M100.975 0.141774C101.652 0.141774 102.253 0.26937 102.779 0.524562C103.305 0.779754 103.747 1.13891 104.104 1.60204C104.47 2.05571 104.743 2.585 104.921 3.1899C105.109 3.78535 105.203 4.42333 105.203 5.10384C105.203 6.02064 105.039 6.8571 104.71 7.61323C104.381 8.3599 103.902 8.95535 103.272 9.39957C102.652 9.84379 101.886 10.0659 100.975 10.0659H97.6069C97.3626 10.0659 97.1559 9.98557 96.9868 9.82489C96.8271 9.65476 96.7472 9.44683 96.7472 9.20109V1.00659C96.7472 0.76085 96.8271 0.557642 96.9868 0.396965C97.1559 0.226837 97.3626 0.141774 97.6069 0.141774H100.975ZM100.834 8.43551C101.426 8.43551 101.91 8.28429 102.286 7.98184C102.662 7.66994 102.934 7.26352 103.103 6.76259C103.282 6.2522 103.371 5.69929 103.371 5.10384C103.371 4.65962 103.319 4.23902 103.216 3.84206C103.122 3.43564 102.972 3.08121 102.765 2.77876C102.558 2.46686 102.295 2.22112 101.976 2.04154C101.656 1.86196 101.276 1.77217 100.834 1.77217H98.3679L98.5089 1.64457V8.59146L98.4243 8.43551H100.834Z"
          fill="#384B96"
        />
        <defs>
          <linearGradient
            id="paint0_linear_386_1145"
            x1="12.5378"
            y1="33.3349"
            x2="68.1285"
            y2="68.2639"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00ADBA" />
            <stop offset="1" stopColor="#384B96" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_386_1145"
            x1="12.5378"
            y1="33.3349"
            x2="68.1285"
            y2="68.2639"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00ADBA" />
            <stop offset="1" stopColor="#384B96" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_386_1145"
            x1="12.5378"
            y1="33.3349"
            x2="68.1285"
            y2="68.2639"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00ADBA" />
            <stop offset="1" stopColor="#384B96" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default Logo
