import React from 'react'
import styles from './Welcome.module.scss'
import { ReactComponent as Logos } from '../../../style/img/footer-logo.svg'

export function BottomFooter(): JSX.Element {
  return (
    <div className={styles.bottomFooter}>
      <div><Logos/></div>
    </div>
  )
}
