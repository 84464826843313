import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from '../../config/store'
import { Dossier } from '../../reducers/dossier/dossier-reducer'

const dossierSelector = createSelector(
  [(state: RootState) => state.dossiers.all],
  (dossiers) => Object.values(dossiers)
)

export const useDossiers = (): Array<Dossier> => {
  return useSelector(dossierSelector)
}