import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import styles from './DossierImporter.module.scss'
import { Link } from 'react-router-dom'
import { ROUTE } from '../../config/route'

interface Option {
  label: string,
  value: string,
  disabled?: boolean
}

const categories: Array<Option> = [
  {label: 'Huisarts', value: 'PHYSICIAN'},
  {label: 'Ziekenhuis', value: 'HOSPITAL', disabled: true},
  {label: 'GGZ', value: 'GGZ', disabled: true},
  {label: 'Kliniek', value: 'CLINIC', disabled: true},
  {label: 'Apotheek', value: 'PHARMACY', disabled: true},
]

const providers: {[category: string]: Array<Option>} = {
  PHYSICIAN: [
    {label: 'Huisartsenpraktijk Azuurblauw', value: 'BBG'},
    {label: 'Huisartsenpraktijk Groengeel', value: 'AZURE', disabled: true},
    {label: 'Huisartsenpraktijk Kopergroen', value: 'GREEN', disabled: true},
  ]
}

export default function DossierImporter(): JSX.Element {
  const {t} = useTranslation()
  const [category, setCategory] = React.useState<Option|null>(null)
  const [provider, setProvider] = React.useState<Option|null>(null)
  const formComplete = (category === null || provider === null) && 'inactive'

  return (
    <div className={styles.dossierImporter}>
      <h2>{t('dossier.importer.title')}</h2>

      <form className="form form-horizontal">
        <div className="row">
          <div className="col-sm-4">
            <label className="form-label" htmlFor="dossier-import__type">{
              t('dossier.importer.type-input.label')
            }</label>
            <Select
              inputId="dossier-import__type"
              options={categories}
              isOptionDisabled={(option) => option.disabled === true}
              placeholder={t('dossier.importer.category.placeholder')}
              value={category}
              onChange={(newCategory) => {
                setProvider(null)
                setCategory(newCategory)
              }}
              isClearable
            />
          </div>

          <div className="col-sm-8">
            <label className="form-label" htmlFor="dossier-import__provider">{
              t('dossier.importer.provider-input.label')
            }</label>
            <Select
              inputId="dossier-import__provider"
              options={category === null ? [] : providers[category.value]}
              isDisabled={category === null}
              placeholder={t('dossier.importer.provider.placeholder')}
              noOptionsMessage={() => 'Geen zorgverleners gevonden'}
              value={provider}
              onChange={setProvider}
              isClearable
              isOptionDisabled={(option) => option.disabled === true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Link className={clsx('btn btn-primary', formComplete, styles.submitImportRequest)} to={ROUTE.DOSSIER_LANDING}>{
              t('dossier.importer.submit')
            }</Link>
          </div>
        </div>
      </form>
    </div>
  )
}
