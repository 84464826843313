import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import BackToDashboardButton from '../../../Button/BackToDashboardButton'
import Table from '../../../Table/Table'
import Layout from '../../Layout'
import styles from './Ggz.module.scss'

const ggzstatus = [
  ['Naam', 'Waarde', 'Datum'],
  ['Ernstige stoornis (aanzienlijk, hoog, sterk) 50-95%', 'moeite met gevaarlijke gedachte weerstaan', '5/7/18'],
  ['Angststoornis', '451 niet in staat gedachten te weerstaan', '7/5/20'],
]

const ggzprobleem = [
  ['Anatomische locatie', 'Lateraliteit', 'Probleemtype', 'Naam', 'Begindatum', 'Einddatum', 'Probleem status', 'Verificatiestatus', 'Toelichting'],
  ['Hoofd', 'Links', 'Diagnose', 'Depressieve stoornis, Eenmalige episode', '7/5/20', '','Actueel', 'Bevestigd', 'Geen toelichting'],
  ['Hoofd', 'Rechts', 'Diagnose', 'Angsstoornis, Meerdere epidoses', '5/7/18', '12/8/20', 'Afgerond', 'Bevestigd', 'Geen toelichting'],
]

const drugs = [
  ['Startdatum', 'Stopdatum', 'Gebruik', 'Soort', 'Status', 'Toediening', 'Hoeveelheid', 'Toelichting'],
  ['1/1/09', '12/31/18', 'Gebruikt drugs', 'Anabole steroïde', 'In het verleden gebruikt', 'Intraveneus (spuiten)', '2x per dag', 'Typ hier uw toelichting'],
]

const alcohol = [
  ['Startdatum', 'Stopdatum', 'Gebruik', 'Hoeveelheid', 'Toelichting'],
  ['12/1/17', '1/1/19', 'Niet-drinker', '1 eenheid per dag', 'Voor het slapen gaan']
]

const tobacco = [
  ['Startdatum', 'Stopdatum', 'Gebruik', 'Soort', 'Status', 'Hoeveelheid', 'Toelichting'],
  ['12/12/19', '12/12/20', 'Ex-roker', 'rookt sigaretten', 'Dagelijks roken', '115 sigaretten per week', 'Echtenote rookt ook']
]

const participation = [
  ['Arbeidssituatie'],
  ['Senior ICT software ontwikkelaar, stuurt team van 10 junior collegas aan als enige senior, ervaart hierdoor veel druk.']
]

const labresult = [
  ['Onderzoek ', 'Resultaat status ', 'Resultaat type ', 'Toelichting'],
  ['234-06 Hemoglobine en hematocriet panel', 'Definitief', 'Klinische chemie', 'In de ochtend geprikt']
]

const labresultsample = [
  ['Lichaamsmateriaal', 'Afname Datum Tijd',],
  ['Bloed', '5/14/20 20:00']
]

const labtest = [
  ['Testcode ', 'Test Datum Tijd ', 'Testuitslag', 'Testuitslag status ', 'Referentie bovengrens', 'Referentie ondergrens ', 'Interpretatie vlaggen ',],
  ['718-7 Hemoglobine', '5/14/20 22:00', '11.5 mmol/l', 'definitief', '16.0 mmol/l', '12.0 mmol/l', 'Onder referentiewaarde',],
  ['20507-04 Hematocriet', '5/15/20 22:00', '45%', 'Definitief', '39%', '51%', 'Binnen referentiekader',]
]

const labquestionnaire = [
  ['Testcode ', 'Test Datum Tijd ', 'Testuitslag', 'Testuitslag status ', 'Referentie bovengrens ', 'Referentie ondergrens ', 'Interpretatie vlaggen ',],
  ['20507-04 Hematocriet', '5/15/20 22:00', '45%', 'Definitief', '39%', '51%', 'Binnen referentiekader',]
]

const generalmeasurement = [
  ['Onderzoek', 'Resultaat status', 'Toelichting',],
  ['273513009 Health of the Nation Outcome Scale (generic version) (assessmentscale)', 'Definitief', 'Schrijf hier uw toelichting'],
]

const generalmeasurementresults = [
  ['Meting naam', 'Uitslagwaarde', 'Uitslag Datum Tijd',],
  ['281116008 Health of the Nation Outcome Scale item 2 - non-accidental selfinjury (assessment scale)', '4', '1/1/20 11:00',],
  ['28111602 Health of the Nation Outcome Scale item 2 ', '5', '5/1/17',],
]

const action = [
  ['Startdatum', 'Einddatum', 'Anatomische locatie', 'Lateraliteit', 'Type', 'Methode',],
  ['5/14/18', '5/14/18', 'Hoofd', 'Links', 'Consult', 'EMDR',],
]

const medicaldevice = [
  ['Producttype', 'ProductID', 'Toelichting',],
  ['Rustgevende lamp', '1234', 'Gebruik in avonden',],
]

export default function Ggz(): JSX.Element {
  const {t} = useTranslation()

  const header = (
    <div className="container">
      <div className="row">
        <div className={styles.intro}>
          <h1>{t('dossier.detail.ggz.title')}</h1>
          <p>{t('dossier.detail.ggz.intro')}</p>
        </div>
      </div>
    </div>
  )

  const main = (
    <div className={clsx('container', styles.ggzMain)}>
      <div className="row">
        <div className="col-12">
          <BackToDashboardButton />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.ggzstatus')}</h2>
          <Table definition={ggzstatus} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.ggzprobleem')}</h2>
          <Table definition={ggzprobleem} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.drugs')}</h2>
          <Table definition={drugs} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.alcohol')}</h2>
          <Table definition={alcohol} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.tobacco')}</h2>
          <Table definition={tobacco} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.participation')}</h2>
          <Table definition={participation} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.labresult')}</h2>
          <Table definition={labresult} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.labresultsample')}</h2>
          <Table definition={labresultsample} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.labtest')}</h2>
          <Table definition={labtest} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.labquestionnaire')}</h2>
          <Table definition={labquestionnaire} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.generalmeasurement')}</h2>
          <Table definition={generalmeasurement} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.generalmeasurementresults')}</h2>
          <Table definition={generalmeasurementresults} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.action')}</h2>
          <Table definition={action} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h2>{t('dossier.detail.ggz.medicaldevice')}</h2>
          <Table definition={medicaldevice} />
        </div>
      </div>
    </div>
  )

  return (
    <Layout top={header} bottom={main} />
  )
}
