import * as React from 'react'
import { useTranslation } from 'react-i18next'
import style from './BackToDashboardButton.module.scss'
import { ROUTE } from '../../config/route'
import { Link } from 'react-router-dom'
import { ReactComponent as Back } from '../../style/img/back-button.svg'

const BackToDashboardButton = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Link to={ROUTE.DASHBOARD} className={style.breadcrumb}>
      <Back /> <span>{t('back_to_dashboard')}</span>
    </Link>
  )
}

export default BackToDashboardButton
