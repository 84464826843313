import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface Vaccination {
  id: string,
  logo: 'hospital' | 'comment' | 'document' | 'doctor',
  title: string,
  retrievedAt: string,
  modal: boolean
  new: boolean
}

interface VaccinationState {
  all: {
    [key: string]: Vaccination
  }
}

const vaccinations: {[key: string]: Vaccination} = {
  '1a': {
    id: '1a',
    logo: 'doctor',
    title: 'GGD/GHOR covid-19 vaccinatie',
    retrievedAt: 'Opgehaald op 22 februari 2022 - 13:35:32',
    modal: true,
    new: false
  },
  '2b': {
    id: '2b',
    logo: 'doctor',
    title: 'GGD/GHOR covid-19 boosterprik',
    retrievedAt: 'Opgehaald op 22 februari 2022 - 13:40:24',
    modal: true,
    new: false
  }
}

const vacccinationSlice = createSlice({
  name: 'dossiers',
  initialState: {
    all: vaccinations
  },
  reducers: {
    addVaccination: (state: VaccinationState, action: PayloadAction<Vaccination>): VaccinationState => {
      // If we do state.all[action.payload.id] = action.payload, it'll appear at the end, but it should be at the front.
      return {
        ...state,
        all: {
          [action.payload.id]: action.payload,
          ...state.all
        }
      }
    }
  }
})

export const {addVaccination} = vacccinationSlice.actions
export default vacccinationSlice.reducer
