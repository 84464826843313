import React from 'react'
import {ReactComponent as MedMij} from '../medmij.svg'
import styles from './Landing.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTE } from '../../../../config/route'

export default function Landing(): JSX.Element {
  const {t} = useTranslation()

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className={styles.landingScreen}>
            <MedMij className={styles.logo} />
            <h1>{t('dossier.landing.welcome')}</h1>
            <p className={styles.explanation} dangerouslySetInnerHTML={{ __html: t('dossier.landing.explanation') }} />
            <div className={styles.submit}>
              <Link className="btn btn-primary" to={ROUTE.DOSSIER_DIGID}>{t('dossier.landing.login')}</Link>
            </div>
            <div className={styles.abort}>
              <Link className={clsx('btn btn-ghost')} to={ROUTE.DOSSIER_DETAIL}>{t('dossier.landing.abort')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
